import React from 'react';
import { Link } from 'gatsby';
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';

import Tam from "../assets/img/tam.svg";
import Zpatky from "../assets/img/zpatky.svg";

const BreadscrumInner = styled.div`
  .breadscrum {

    .mobilni {
      font-family: Visuelt-Regular;
      text-decoration: none;
      font-size: 16px;
      color: #8b8b8b;

      &:hover {
        color: #313131;
        text-decoration: underline;
      }

      &:before {
        content: " ";
        width: 9px;
        height: 15px;
        background: url(${Zpatky});
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 12px;
        display: inline-block;
        position: relative;
        top: 2px;
      }
    }

    ol {
      padding: 0;
      margin: 0;

      li {
        font-family: Visuelt-Regular;
        font-size: 14px;
        color: #8b8b8b;
        list-style: none;
        display: inline-block;

        &:after {
          content: "";
          background: url(${Tam});
          margin-right: 10px;
          margin-left: 10px;
          height: 15px;
          width: 7px;
          display: inline-block;
          vertical-align: middle;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          position: relative;
          top: -2px;
        }

        &:last-child {
          &:after {
            content: none;
          }
        }

        a {
          font-family: Visuelt-Regular;
          font-size: 14px;
          color: #8b8b8b;
          text-decoration: none;

          &:hover {
            color: #313131;
            text-decoration: underline;
          }
        }
      }
    }
    
  }
`;

const Breadscrum = ({ stranky, visible }) => {

  const isPlus900 = useMediaQuery({
    query: '(min-width: 900px)'
  });



  let code = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": []
  };

  let pocitadlo = 0;

  stranky.forEach(stranka => {
    pocitadlo++;

    code.itemListElement.push({
      "@type": "ListItem",
      "position": pocitadlo,
      "name": stranka.name,
      "item": `https://goodbye.cz${stranka.url}`
    });
  });


  let pocitadlo2 = 0;
  
  return (
    <BreadscrumInner>
      <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(code)}}/>
      {(visible) ? 
        <div className="breadscrum">
          {(isPlus900) ?
          <ol>
            {stranky.map(stranka => {
              pocitadlo2++;
              
              return (
                (pocitadlo2 === stranky.length)
                ?
                <li key={pocitadlo2}>
                  {stranka.name}
                </li>
                :
                <li key={pocitadlo2}>
                  <Link to={`${stranka.url}`}>{stranka.name}</Link>
                </li>
            )})}
          </ol>
          :
          <Link className="mobilni" to={`${stranky[stranky.length - 2].url}`}>{stranky[stranky.length - 2].name}</Link>
          }
        </div>
      : null}
    </BreadscrumInner>
  );
}

export default Breadscrum;
